import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { sortBy } from 'lodash'

import ZiplogTable from 'components/shared/ziplogTable/ziplogTable'
import { auditLogsApi } from 'apis/auditLogs.api'

import './auditLogsTable.css'

class AuditLogsTable extends Component {
  fields = ['timestamp', 'email', 'action', 'payload', '']

  handleClick = async filename => {
    try {
      const response = await auditLogsApi.fetchFileContent(filename)
      const match = filename.match(/https?:\/\/[^/]+\/(.+)/)
      const extractedFilename = match ? match[1] : filename
      auditLogsApi.downloadFile(response, extractedFilename)
    } catch (error) {
      console.error(error)
    }
  }

  sortedData = () => {
    const auditLogs = this.props.data || []
    const data = sortBy(auditLogs, log => -Date.parse(log.timestamp))
    data.forEach((log, i) => {
      const filename = this.extractFilename(log.payload)

      log[''] = filename ? (
        <button
          key={`download-btn-${i}`}
          className='audit-logs-download-button'
          onClick={() => this.handleClick(this.extractFilename(log.payload))}
        >
          <span>Download</span>
        </button>
      ) : (
        ''
      )
    })
    return data
  }

  extractFilename = payload => {
    try {
      const parsedPayload = JSON.parse(payload)
      return parsedPayload.file
    } catch (error) {
      return ''
    }
  }

  render() {
    if (this.props.isLoading) {
      return <div className='audit-logs-table__loader'>Loading...</div>
    }

    return (
      <div>
        {this.props.data?.length > 0 && (
          <ZiplogTable
            tableName='audit-logs-table'
            headers={this.fields}
            data={this.sortedData()}
          />
        )}
      </div>
    )
  }
}

AuditLogsTable.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      timestamp: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      action: PropTypes.string.isRequired,
      payload: PropTypes.string.isRequired,
    }),
  ),
  responseBody: PropTypes.object,
}

export default AuditLogsTable
