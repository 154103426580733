import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

const CutoffDateSelect = ({ deliveryDate, cutoffDate, onSelect }) => {
  const [selected, setSelected] = useState(cutoffDate)

  useEffect(() => {
    setSelected(cutoffDate)
  }, [cutoffDate])

  const formatDate = dateString => {
    return dateString ? moment(dateString).format('ddd, MMMM D') : null
  }

  const cutoffDatesInWeek = () => {
    // Subtract 2 days because 1 day cutoff is not allowed
    const startDate = moment(deliveryDate).subtract(2, 'days')
    // Get the last 5 days because we skip the day
    // before the exceptional delivery date
    // Example: for 28th Jan delivery date > cutoffs can be 22th Jan to 26th Jan
    const cutoffDaysRange = 5
    let dates = []

    for (let i = 0; i < cutoffDaysRange; i++) {
      const newDate = startDate.clone().subtract(i, 'days').format('YYYY-MM-DD')
      if (moment(newDate).isBefore(moment(deliveryDate))) {
        dates.push(newDate.toString())
      }
    }

    return dates
  }

  const renderOptions = () => {
    const dates = cutoffDatesInWeek()
    let optionsHtml = [
      <option key='no-date-selected' value=''>
        Select Date
      </option>,
    ]

    dates.forEach(date => {
      optionsHtml.push(
        <option key={date} value={date}>
          {formatDate(date)}
        </option>,
      )
    })

    return optionsHtml
  }

  const handleChange = event => {
    const newCutoffDate = event.target.value
    setSelected(newCutoffDate)
    onSelect('newCutoffDate', newCutoffDate)
  }

  return deliveryDate ? (
    <select
      className='cutoff-date-select'
      name='newCutoffDate'
      onChange={handleChange}
      value={selected}
    >
      {renderOptions()}
    </select>
  ) : null
}

CutoffDateSelect.propTypes = {
  deliveryDate: PropTypes.string,
  cutoffDate: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
}

export default CutoffDateSelect
