import { call, put, takeLatest } from 'redux-saga/effects'

import authenticationApi from 'apis/authentication.api'
import { deliveryOptionsApi } from 'apis/deliveryOptions.api'
import {
  DELIVERY_OPTIONS_UPDATE_SUCCEEDED,
  DELIVERY_OPTIONS_UPDATE_FAILED,
  DELIVERY_OPTIONS_UPDATE_REQUESTED,
} from 'actions/types'

import { openSuccessAlert } from 'actions/alert/alert'

export function* updateDeliveryOptions(action) {
  try {
    const response = yield call(
      deliveryOptionsApi.updateDeliveryOptions,
      action.country,
      action.brand,
      action.csvContent,
      action.firstDeliveryWeek,
    )

    yield call(handleResponse, response)
  } catch (error) {
    const failureAction = deliveryOptionsFailedAction()

    authenticationApi.handleError(error, action, failureAction)
    yield put(failureAction)
  }
}

function* handleResponse(response) {
  if (response.body.errors) {
    yield put(deliveryOptionsFailedAction(response.body))
  } else {
    yield put(openSuccessAlert(response.body.message))
    yield put({
      type: DELIVERY_OPTIONS_UPDATE_SUCCEEDED,
    })
  }
}

function deliveryOptionsFailedAction(responseBody = null) {
  return {
    type: DELIVERY_OPTIONS_UPDATE_FAILED,
    data: { responseBody: responseBody },
  }
}

export const updateDeliveryOptionsSagas = [
  takeLatest(DELIVERY_OPTIONS_UPDATE_REQUESTED, updateDeliveryOptions),
]
