import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import DeliveryDateSelect from '../deliveryDateSelect/deliveryDateSelect'
import SlotSelect from '../slotSelect/slotSelect'
import TypeSelect from '../typeSelect/typeSelect'
import DeleteButton from '../deleteButton/deleteButton'
import { newExceptionSelect, newExceptionClear } from 'actions/deliveryDateExceptions'
import { helpers } from 'helpers/helpers'
import formatDate from 'helpers/formatDate'
import './deliveryDateExceptionsTableRow.css'
import CutoffDateSelect from 'components/deliveryDateExceptions/cutoffDateSelect/cutoffDateSelect'

export class DeliveryDateExceptionsTableRow extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      newDeliveryDate: '',
      newShipmentDate: '',
      newCutoffDate: '',
      newDeliverySlot: '',
      newExceptionType: 'holiday',
    }
  }

  _formatRegionWithProductionInfo(region, transitTime, productionSite) {
    return `${region} (${productionSite}, ${transitTime})`
  }

  _formatSlotInfo(slotInfo) {
    const slotDescription = `${slotInfo['shipperName']} ${slotInfo['from']}-${slotInfo['to']}`
    const priceTierIdentifier = slotInfo['priceTierIdentifier']

    return priceTierIdentifier ? `${slotDescription}, ${priceTierIdentifier}` : slotDescription
  }

  _ableToAddExceptions(option) {
    const exceptionalDate = option.exceptionalDeliveryDate
    const { currentUserCanCreateExceptions } = this.props

    if (exceptionalDate) return false

    return currentUserCanCreateExceptions
  }

  defaultCutoffDateValue = (component, value) => {
    if (component === 'newDate') return this.newCutoffDate(value)
    return this.state.newCutoffDate
  }

  updateNewExceptionData = (name, value) => {
    const newDate = name === 'newDate' ? value : this.state.newDeliveryDate
    const newSlot = name === 'newSlot' ? value : this.state.newDeliverySlot
    const newType = name === 'newType' ? value : this.state.newExceptionType
    const newCutoffDate =
      name === 'newCutoffDate' ? value : this.defaultCutoffDateValue(name, value)

    newDate
      ? this._addNewException(
          newDate,
          this.newShipmentDate(newDate),
          newCutoffDate,
          newSlot,
          newType,
        )
      : this._clearNewException()
  }

  _addNewException(newDate, newShipmentDate, newCutoffDate, newSlot, newType) {
    const action = newExceptionSelect(
      newDate,
      newShipmentDate,
      newCutoffDate,
      newSlot,
      newType,
      this.props.data,
    )
    this.props.dispatch(action)

    this.setState({
      newDeliveryDate: newDate,
      newShipmentDate: newShipmentDate,
      newCutoffDate: newCutoffDate,
      newDeliverySlot: newSlot,
      newExceptionType: newType,
    })
  }

  newShipmentDate(deliveryDate) {
    return helpers.shipmentDate(this.props.data.transitTime, deliveryDate)
  }

  newCutoffDate(deliveryDate) {
    return helpers.cutoffDate(this.props.data.cutoffDays, deliveryDate)
  }

  _clearNewException() {
    const action = newExceptionClear(this.props.data)
    this.props.dispatch(action)

    this.setState({
      newDeliveryDate: '',
      newShipmentDate: '',
      newCutoffDate: '',
      newDeliverySlot: '',
      newExceptionType: 'holiday',
    })
  }

  _displayDeliveryDate(option) {
    if (this._ableToAddExceptions(option)) {
      return (
        <DeliveryDateSelect
          deliveryDate={option['regularDeliveryDate']}
          onSelect={this.updateNewExceptionData}
        />
      )
    } else {
      return formatDate(option['exceptionalDeliveryDate'])
    }
  }

  _displayShipmentDate(option) {
    return formatDate(option['exceptionalShipmentDate'] || this.state.newShipmentDate)
  }

  _displayCutoffDate(option) {
    if (this._ableToAddExceptions(option)) {
      const cutoffDate = this.state.newCutoffDate || option['regularCutoffDate']
      return (
        <CutoffDateSelect
          deliveryDate={this.state.newDeliveryDate}
          cutoffDate={cutoffDate}
          onSelect={this.updateNewExceptionData}
        />
      )
    } else {
      return formatDate(option['exceptionalCutoffDate'] || this.state.newCutoffDate)
    }
  }

  _displayNewSlot(option) {
    let slotHTML = ''

    if (this._ableToAddExceptions(option)) {
      slotHTML = (
        <SlotSelect
          deliveryOptions={this.props.deliveryOptions}
          regularData={option}
          onSelect={this.updateNewExceptionData}
          enabled={!!this.state.newDeliveryDate}
        />
      )
    } else {
      if (option['exceptionalSlot']) {
        slotHTML = this._formatSlotInfo(option['exceptionalSlot'])
      }
    }

    return slotHTML
  }

  _displayType(option) {
    if (this._ableToAddExceptions(option)) {
      return (
        <TypeSelect
          type={this.state.newExceptionType}
          onSelect={this.updateNewExceptionData}
          enabled={!!this.state.newDeliverySlot}
        />
      )
    } else {
      return option['exceptionType']
    }
  }

  render() {
    const data = this.props.data
    const text = this._formatRegionWithProductionInfo(
      data.region,
      data.transitTime,
      data.productionSite,
    )

    return (
      <tr className='delivery-date-exceptions-table-row' key={text}>
        <td>{text}</td>
        {helpers.showSuburbs(this.props.country) && <td>{data.suburb}</td>}
        <td>{formatDate(data.regularCutoffDate)}</td>
        <td>{formatDate(data.regularShipmentDate)}</td>
        <td className='delivery-date-exceptions-table-row__regular-delivery-date'>
          {formatDate(data.regularDeliveryDate)}
        </td>
        <td className='delivery-date-exceptions-table-row__regular-slot'>
          {this._formatSlotInfo(data.regularSlot)}
        </td>
        <td className='delivery-date-exceptions-table-row__new-cutoff-date'>
          {this._displayCutoffDate(data)}
        </td>
        <td className='delivery-date-exceptions-table-row__new-production-date'>
          {this._displayShipmentDate(data)}
        </td>
        <td className='delivery-date-exceptions-table-row__new-delivery-date'>
          {this._displayDeliveryDate(data)}
        </td>
        <td>{this._displayNewSlot(data)}</td>
        <td>{this._displayType(data)}</td>
        <td>
          <DeleteButton
            exceptionToDelete={data}
            currentUserCanDestroyExceptions={this.props.currentUserCanDestroyExceptions}
          />
        </td>
      </tr>
    )
  }
}

DeliveryDateExceptionsTableRow.propTypes = {
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.object,
  deliveryOptions: PropTypes.array,
  country: PropTypes.string.isRequired,
  currentUserCanCreateExceptions: PropTypes.bool.isRequired,
  currentUserCanDestroyExceptions: PropTypes.bool.isRequired,
}

const connectedDeliveryDateExceptionsTableRow = connect()(DeliveryDateExceptionsTableRow)

export default connectedDeliveryDateExceptionsTableRow
