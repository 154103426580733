import React from 'react'
import { connect } from 'react-redux'
import { filter } from 'lodash'
import queryString from 'query-string'
import PropTypes from 'prop-types'
import CountryFilter from 'components/shared/countryFilter/countryFilter'
import DeliveryDateExceptionsPostButton from './deliveryDateExceptionsPostButton/deliveryDateExceptionsPostButton'
import OrderGenerationMessage from './orderGenerationMessage/orderGenerationMessage'
import DeliveryDateExceptionsTable from './deliveryDateExceptionsTable/deliveryDateExceptionsTable'
import DeliveryDateExceptionsFilters from './deliveryDateExceptionsFilters/deliveryDateExceptionsFilters'
import ExceptionsSaveButton from './exceptionsSaveButton/exceptionsSaveButton'
import RegularDeliveryDatePicker from 'components/shared/regularDeliveryDatePicker/regularDeliveryDatePicker'
import { clearData, fetchData, setGlobalFilters } from 'actions/index'
import { helpers } from 'helpers/helpers'
import { DELIVERY_DATE_EXCEPTIONS, DELIVERY_EXCEPTIONS_PERMISSIONS } from 'actions/context'

import './deliveryDateExceptions.css'
import { MultipleBrandsFilter } from 'components/shared/multipleBrandFilter/multipleBrandsFilter'
import InfoMessage from 'components/shared/infoMessage/infoMessage'

const DEFAULT_ORDER_TYPE = 'regular'

export class DeliveryDateExceptions extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeFilters: {},
    }

    this.updatePrimaryFilters(this.props.location.search)
  }

  componentDidMount() {
    this.requestDeliveryOptions()
    this.fetchDeliveryExceptionsPermissionsData()
  }

  componentDidUpdate(prevProps) {
    if (this.props.country !== prevProps.country) {
      this.requestDeliveryOptions()
      this.setState({ activeFilters: {} })
    }

    if (this.props.brands !== prevProps.brands) {
      this.setState({ activeFilters: {} })
      this.props.dispatch(clearData(DELIVERY_DATE_EXCEPTIONS))
    }

    if (
      this.props.country !== prevProps.country ||
      this.props.regularDeliveryDate !== prevProps.regularDeliveryDate
    ) {
      this.fetchDeliveryExceptionsPermissionsData()
    }
  }

  updatePrimaryFilters(searchString) {
    if (searchString.length > 0) {
      const queryObj = queryString.parse(searchString)
      const data = this.generateGlobalFiltersData(queryObj)

      this.props.dispatch(setGlobalFilters(data))
      this.fetchDeliveryDateExceptionsData(queryObj)
    }
  }

  fetchDeliveryDateExceptionsData(queryObj) {
    if (queryObj.country) {
      const data = {
        country: queryObj.country,
        brand: queryObj.brand || 'ms',
        regularDeliveryDate: queryObj.regularDeliveryDate || helpers.today(),
        orderType: DEFAULT_ORDER_TYPE,
      }

      this.props.dispatch(fetchData(DELIVERY_DATE_EXCEPTIONS, data))
    }
  }

  fetchDeliveryExceptionsPermissionsData() {
    const { country, regularDeliveryDate, dispatch } = this.props
    const data = { country, regularDeliveryDate }

    if (country && regularDeliveryDate) {
      dispatch(fetchData(DELIVERY_EXCEPTIONS_PERMISSIONS, data))
    }
  }

  generateGlobalFiltersData(queryObj) {
    let data = {}
    const arr = ['country', 'brand', 'regularDeliveryDate', 'orderType']

    arr.forEach(key => {
      if (queryObj[key]) {
        data[key] = queryObj[key].toLowerCase()
      }
    })

    return data
  }

  updateActiveFilters = newFilters => {
    this.setState({
      activeFilters: newFilters,
    })
    this.requestDeliveryOptions()
  }

  requestDeliveryOptions() {
    const { country, brand, dispatch } = this.props

    if (country && brand) {
      dispatch({
        type: '[DELIVERY_OPTIONS]_FETCH_REQUESTED',
        data: { country, brand, orderType: DEFAULT_ORDER_TYPE },
      })
    }
  }

  groupOptionsByBrand(options) {
    const groupedByData = options?.reduce((acc, option) => {
      const { brand, ...rest } = option
      const key = JSON.stringify(rest)

      if (!acc[key]) {
        acc[key] = {
          multipleBrands: [],
          data: rest,
        }
      }
      if (!acc[key].multipleBrands.includes(brand)) {
        acc[key].multipleBrands.push(brand)
      }
      return acc
    }, {})

    if (groupedByData) {
      const res = Object.values(groupedByData).filter(
        option =>
          option.multipleBrands.length == this.props.brands.length &&
          option.multipleBrands.every(b => this.props.brands.includes(b)),
      )
      return res
    }
    return null
  }

  filteredDeliveryOptions() {
    const groupedByBrands = this.groupOptionsByBrand(this.props.exceptionsData)
    return groupedByBrands
      ? filter(
          groupedByBrands.map(o => o.data),
          this.state.activeFilters,
        )
      : null
  }

  componentWillUnmount() {
    this.props.dispatch(clearData(DELIVERY_DATE_EXCEPTIONS))
  }

  render() {
    return (
      <div className='delivery-date-exceptions'>
        <div className='delivery-date-exceptions__required-filters'>
          <CountryFilter
            context={DELIVERY_DATE_EXCEPTIONS}
            countries={this.props.countriesAllowedToView}
          />
          <MultipleBrandsFilter dispatch={this.props.dispatch} />
          <RegularDeliveryDatePicker context={DELIVERY_DATE_EXCEPTIONS} />

          <div className='delivery-date-exceptions__post-button'>
            <DeliveryDateExceptionsPostButton />
          </div>
        </div>

        <InfoMessage
          message={
            'Selecting more than one brand will display only the slots that are common to those brands and apply exceptions to all of these respective slots.'
          }
          className='warning-message'
        />

        <OrderGenerationMessage
          country={this.props.country}
          brand={this.props.brand}
          date={this.props.regularDeliveryDate}
          orderType={DEFAULT_ORDER_TYPE}
        />

        <DeliveryDateExceptionsFilters
          onFiltersUpdate={this.updateActiveFilters}
          activeFilters={this.state.activeFilters}
          data={this.filteredDeliveryOptions()}
          country={this.props.country}
        />

        <DeliveryDateExceptionsTable
          isLoading={this.props.exceptionsIsLoading}
          options={this.filteredDeliveryOptions()}
          country={this.props.country}
          deliveryOptions={this.props.deliveryOptions}
          orderType={DEFAULT_ORDER_TYPE}
          currentUserCanCreateExceptions={this.props.canCreate}
          currentUserCanDestroyExceptions={this.props.canDestroy}
        />

        <ExceptionsSaveButton
          regularDate={this.props.regularDeliveryDate}
          orderType={DEFAULT_ORDER_TYPE}
          currentUserCanCreateExceptions={this.props.canCreate}
        />
      </div>
    )
  }
}

DeliveryDateExceptions.propTypes = {
  dispatch: PropTypes.func.isRequired,
  country: PropTypes.string,
  brand: PropTypes.string,
  brands: PropTypes.array,
  deliveryOptions: PropTypes.array,
  exceptionsIsLoading: PropTypes.bool,
  exceptionsData: PropTypes.object,
  orderType: PropTypes.string,
  regularDeliveryDate: PropTypes.string,
  countriesAllowedToView: PropTypes.array,
  canCreate: PropTypes.bool,
  canDestroy: PropTypes.bool,
  location: PropTypes.object,
}

function mapStateToProps(state) {
  return {
    country: state.globalFilters.country,
    brand: state.globalFilters.brand,
    brands: state.globalFilters.multipleBrands,
    deliveryOptions: state.deliveryOptions.data,
    exceptionsIsLoading: state.deliveryDateExceptions.isLoading,
    exceptionsData: state.deliveryDateExceptions.data,
    orderType: state.globalFilters.orderType,
    regularDeliveryDate: state.globalFilters.regularDeliveryDate,
    countriesAllowedToView: state.currentUser.countriesAllowedToView,
    canCreate: state.deliveryExceptionsPermissions.data.canCreate,
    canDestroy: state.deliveryExceptionsPermissions.data.canDestroy,
  }
}

const connectedDeliveryDateExceptions = connect(mapStateToProps)(DeliveryDateExceptions)

export default connectedDeliveryDateExceptions
