import Papa from 'papaparse'
import { isEmpty, camelCase, toNumber, isNaN } from 'lodash'

const MIN_CUTOFF_DAYS_FOR_SECOND_BILLING = 3

const NEW_CUTOFF_DAYS_FIELD_NAME = 'cutoffDays'
const UPDATE_CUTOFF_DAYS_FIELD_NAME = 'newCutoffDays'

const CUTOFF_DAYS_FIELDS = [
  {
    operationType: 'create',
    fieldName: NEW_CUTOFF_DAYS_FIELD_NAME,
  },
  {
    operationType: 'update',
    fieldName: UPDATE_CUTOFF_DAYS_FIELD_NAME,
  },
]

// Detects whether csv contains delivery options with cut off days lower than 3
// If cut off days less than 3 days then second billing run is not possible
//
// operationType possible values: create, update
export const csvContainsLowCutoffDays = (csvData, operationType) => {
  const { data, meta } = Papa.parse(csvData, {
    header: true,
    delimiter: ',',
    transformHeader: header => camelCase(header),
  })

  const operationTypes = CUTOFF_DAYS_FIELDS.map(f => f.operationType)

  if (!operationTypes.includes(operationType)) {
    console.error(`Invalid operation type: ${operationType}. Possible types: ${operationTypes}`)

    return
  }

  // selecting field that we will search through
  const cutoffFieldName = CUTOFF_DAYS_FIELDS.find(f => f.operationType === operationType).fieldName

  if (!meta.fields.includes(cutoffFieldName)) return false

  const lowCutOffValue = data.find(row => {
    const cutoffValue = row[cutoffFieldName]

    if (isEmpty(cutoffValue)) return false

    const cutoffNumber = toNumber(cutoffValue)

    if (isNaN(cutoffNumber)) return false

    return cutoffNumber < MIN_CUTOFF_DAYS_FOR_SECOND_BILLING
  })

  return lowCutOffValue ? true : false
}
