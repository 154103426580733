import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Alert from '@mui/material/Alert'

import { getContinentByCountry } from 'helpers/countryHelper'
import { pickCSVTemplate } from 'config/csvTemplates'
import CountryFilter from 'components/shared/countryFilter/countryFilter'
import CsvUploader from 'components/shared/csvUploader/csvUploader'
import BrandFilter from 'components/shared/brandFilter/brandFilter'
import ZiplogLink from 'components/shared/ziplogLink/ziplogLink'
import { csvContainsLowCutoffDays } from 'helpers/csvLowCutoffDaysChecker'
import FirstDeliveryWeekPicker from '../deliveryOptionsUpdate/firstDeliveryWeekPicker/firstDeliveryWeekPicker'

import { showModal } from 'actions/modal'
import { DELIVERY_OPTIONS_NEW_PREVIEW_MODAL } from 'components/modal/modalTypes'

import './deliveryOptionsNew.css'
import { createDeliveryOptionsCsvUpload } from 'actions/deliveryOptions'
import FlashMessage from 'components/flashMessage/flashMessage'

export class DeliveryOptionsNew extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedFirstDeliveryWeek: null,
      showMessage: false,
      showCutoffDaysWarning: false,
    }
  }

  openPreviewModal = () => {
    this.props.dispatch(
      showModal(DELIVERY_OPTIONS_NEW_PREVIEW_MODAL, {
        showCutoffDaysWarning: this.state.showCutoffDaysWarning,
        firstDeliveryWeek: this.state.selectedFirstDeliveryWeek,
      }),
    )
  }

  onFileUpload = csvData => {
    this.setState({ showCutoffDaysWarning: csvContainsLowCutoffDays(csvData, 'create') })

    this.props.dispatch(createDeliveryOptionsCsvUpload(csvData))
  }

  isBtnDisabled() {
    const { country, brand, csvContent } = this.props
    return !country || !brand || !csvContent
  }

  componentDidMount() {
    this.setState({
      showMessage: false,
      showCutoffDaysWarning: false,
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.csvContent !== prevProps.csvContent) {
      this.setState({ selectedFirstDeliveryWeek: null })
    }

    if (this.props.responseBody && this.props.responseBody !== prevProps.responseBody) {
      this.setState({ showMessage: !!this.props.responseBody })
    }
  }

  hideMessage = () => this.setState({ showMessage: false })

  setFirstDeliveryWeek = selectedFirstDeliveryWeek => {
    this.setState({ selectedFirstDeliveryWeek })
  }

  getTemplateByType = type => {
    const { country } = this.props
    if (!country) return null

    const continent = getContinentByCountry(country)

    return pickCSVTemplate('add-delivery-options', continent, type)
  }

  renderTemplateLinks = () => {
    const { country } = this.props
    if (country) {
      return (
        <div className='delivery-options-new__template-links'>
          <ZiplogLink
            href={this.getTemplateByType('region')}
            className='delivery-options-new__ziplog-link'
            text='CSV Template by Region'
            openInNewTab
          />
          <ZiplogLink
            href={this.getTemplateByType('zip')}
            className='delivery-options-new__ziplog-link'
            text='CSV Template by Zipcode'
            openInNewTab
          />
        </div>
      )
    } else {
      return (
        <Alert severity='info' sx={{ width: 'max-content', margin: '10px 0 20px' }}>
          Please select a country to view templates
        </Alert>
      )
    }
  }

  render() {
    const { isLoading, canManage } = this.props

    if (isLoading) {
      return <div className='delivery-options-new__loader'>Loading...</div>
    } else if (canManage) {
      return (
        <div className='delivery-options-new'>
          <FlashMessage
            showMessage={this.state.showMessage}
            message={this.props?.responseBody?.message || ''}
            errors={this.props?.responseBody?.errors || []}
            onClose={this.hideMessage}
            showNewDeliveryOption={false}
          />
          <CountryFilter countries={this.props.countriesAllowedToManage} />
          <BrandFilter />

          <CsvUploader onFileUpload={this.onFileUpload} />

          {this.renderTemplateLinks()}

          <FirstDeliveryWeekPicker
            allowNullFirstOption={true}
            selectedFirstDeliveryWeek={this.state.selectedFirstDeliveryWeek}
            onChange={this.setFirstDeliveryWeek}
          />

          <button
            className='delivery-options-new__save-button button-primary'
            disabled={this.isBtnDisabled()}
            onClick={this.openPreviewModal}
          >
            Save
          </button>
        </div>
      )
    }

    return null
  }
}

DeliveryOptionsNew.propTypes = {
  dispatch: PropTypes.func.isRequired,
  country: PropTypes.string,
  brand: PropTypes.string,
  csvContent: PropTypes.string,
  isLoading: PropTypes.bool,
  canManage: PropTypes.bool.isRequired,
  countriesAllowedToManage: PropTypes.array.isRequired,
  responseBody: PropTypes.object,
}

function mapStateToProps(state) {
  return {
    country: state.globalFilters.country,
    brand: state.globalFilters.brand,
    csvContent: state.deliveryOptionsNew.csvContent,
    isLoading: state.deliveryOptionsNew.isLoading,
    responseBody: state.deliveryOptionsNew.responseBody,
    canManage: state.currentUser.canManage,
    countriesAllowedToManage: state.currentUser.countriesAllowedToManage,
  }
}

const connectedDeliveryOptionsNew = connect(mapStateToProps)(DeliveryOptionsNew)

export default connectedDeliveryOptionsNew
