import React from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import LogIcon from './logIcon'
import ZiplogLink from 'components/shared/ziplogLink/ziplogLink'

import './navigation.css'

export const Navigation = props => {
  const [isCollapsed, setIsCollapsed] = React.useState(true)

  const displayIcon = () => (isCollapsed ? 'Menu' : '\u2715')

  const toggleTabletCollapse = () => setIsCollapsed(!isCollapsed)

  const isDeliveryCoveragePath = () => {
    const deliveryCoveragePaths = [
      '/delivery_coverage',
      '/zipcodes/new',
      '/zipcodes/update',
      '/delivery_options/new',
      '/delivery_options/update',
      '/delivery_options/dynamic_options',
      '/delivery_options/pause',
    ]

    return deliveryCoveragePaths.includes(props.location.pathname)
  }

  const isShippingLabelPath = () => {
    const shippingLabelPaths = ['/shipping_labels', '/missing_labels']

    return shippingLabelPaths.includes(props.location.pathname)
  }

  const isDeliveryTrackingPath = () => {
    const deliveryTrackingPaths = [
      '/delivery_tracking',
      '/tracking_activities_csvs',
      '/tracking_csvs',
    ]

    return deliveryTrackingPaths.includes(props.location.pathname)
  }

  const isOrderMovementPath = () => {
    const orderMovementPaths = ['/order_movement', '/failed_order_movements']

    return orderMovementPaths.includes(props.location.pathname)
  }

  const navigationHTML = (
    <React.Fragment>
      <a className='navigation__menu' onClick={toggleTabletCollapse}>
        {displayIcon()}
      </a>
      <ul className={`navigation__list ${isCollapsed ? 'tablet-collapsed' : ''}`}>
        <li className='navigation__list-item'>
          <NavLink
            to='/delivery_coverage'
            isActive={isDeliveryCoveragePath}
            activeClassName='active'
            className='navigation__link'
          >
            <span>Delivery Coverage</span>
          </NavLink>
        </li>
        {props.canManage && (
          <li className='navigation__list-sub-item'>
            <NavLink to='/zipcodes/new' activeClassName='active' className='navigation__sub-link'>
              <span>Add Zipcodes</span>
            </NavLink>
          </li>
        )}
        {props.canManage && (
          <li className='navigation__list-sub-item'>
            <NavLink
              to='/zipcodes/update'
              activeClassName='active'
              className='navigation__sub-link'
            >
              <span>Update Zipcodes</span>
            </NavLink>
          </li>
        )}
        {props.canManage && (
          <li className='navigation__list-sub-item'>
            <NavLink
              to='/delivery_options/new'
              activeClassName='active'
              className='navigation__sub-link'
            >
              <span>Add Delivery Options</span>
            </NavLink>
          </li>
        )}
        {props.canManage && (
          <li className='navigation__list-sub-item'>
            <NavLink
              to='/delivery_options/update'
              activeClassName='active'
              className='navigation__sub-link'
            >
              <span>Update Delivery Options</span>
            </NavLink>
          </li>
        )}
        {props.canManage && (
          <li className='navigation__list-sub-item'>
            <NavLink
              to='/delivery_options/dynamic_options'
              activeClassName='active'
              className='navigation__sub-link'
            >
              <span>Update Dynamic Options</span>
            </NavLink>
          </li>
        )}
        {props.isSuperUser && (
          <li className='navigation__list-sub-item'>
            <NavLink
              to='/delivery_options/pause'
              activeClassName='active'
              className='navigation__sub-link'
            >
              <span>Pause Delivery Options</span>
            </NavLink>
          </li>
        )}
        {props.canManage && (
          <li className='navigation__list-item'>
            <NavLink
              isActive={isOrderMovementPath}
              to='/order_movement'
              activeClassName='active'
              className='navigation__link'
            >
              <span>Order Movement</span>
            </NavLink>
          </li>
        )}
        {props.canManage && (
          <li className='navigation__list-sub-item'>
            <NavLink
              to='/failed_order_movements'
              activeClassName='active'
              className='navigation__sub-link'
            >
              <span>Failed order movements</span>
            </NavLink>
          </li>
        )}
        <li className='navigation__list-item'>
          <NavLink to='/exceptions' activeClassName='active' className='navigation__link'>
            <span>Exceptions</span>
          </NavLink>
        </li>
        <li className='navigation__list-item'>
          <NavLink
            to='/shipping_labels'
            isActive={isShippingLabelPath}
            activeClassName='active'
            className='navigation__link'
          >
            <span>Shipping Labels</span>
          </NavLink>
        </li>
        <li className='navigation__list-sub-item'>
          <NavLink to='/missing_labels' activeClassName='active' className='navigation__sub-link'>
            <span>Missing Labels</span>
          </NavLink>
        </li>
        <li className='navigation__list-item'>
          <NavLink to='/shipping_csvs' activeClassName='active' className='navigation__link'>
            <span>Shipping CSVs</span>
          </NavLink>
        </li>
        <li className='navigation__list-item'>
          <NavLink
            to='/delivery_tracking'
            isActive={isDeliveryTrackingPath}
            activeClassName='active'
            className='navigation__link'
          >
            <span>Delivery Tracking</span>
          </NavLink>
        </li>
        <span>
          <li className='navigation__list-sub-item'>
            <NavLink
              to='/tracking_activities_csvs'
              activeClassName='active'
              className='navigation__sub-link'
            >
              <span>Shipment History CSVs</span>
            </NavLink>
          </li>
          <li className='navigation__list-sub-item'>
            <NavLink to='/tracking_csvs' activeClassName='active' className='navigation__sub-link'>
              <span>Shipment Status CSVs</span>
            </NavLink>
          </li>
        </span>
        {props.isAdmin && (
          <li className='navigation__list-item'>
            <NavLink to='/users' activeClassName='active' className='navigation__link'>
              <span>Users</span>
            </NavLink>
          </li>
        )}
        <li className='navigation__list-item'>
          <NavLink to='/shippers' activeClassName='active' className='navigation__link'>
            <span>Shippers</span>
          </NavLink>
        </li>
        <li className='navigation__list-item navigation__list_item_bottom'>
          <ZiplogLink
            href='https://marleyspoon.atlassian.net/wiki/spaces/SE/pages/2536407108/Ziplog+-+UI+tool+for+logistics+management'
            className='navigation__link navigation__how-to-link'
            openInNewTab
          >
            <img
              className='how_to_link-icon'
              src='images/how-to-icon.svg'
              alt='question mark icon'
            />
          </ZiplogLink>
        </li>
        {props.canManage && (
          <li className='navigation__list-item'>
            <NavLink
              to='/audit_logs'
              activeClassName='active'
              className='navigation__link navigation__audit-logs-link'
            >
              <LogIcon className='log-icon' />
            </NavLink>
          </li>
        )}
      </ul>
    </React.Fragment>
  )

  return <div className='navigation'>{props.isLoggedIn ? navigationHTML : null}</div>
}

Navigation.propTypes = {
  location: PropTypes.object,
  canManage: PropTypes.bool,
  isSuperUser: PropTypes.bool,
  isAdmin: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  countriesAllowedToManage: PropTypes.array,
}

function mapStateToProps(state) {
  return {
    isLoggedIn: state.currentUser.isLoggedIn,
    canManage: state.currentUser.canManage,
    isAdmin: state.currentUser.roles.includes('admin'),
    isSuperUser: state.currentUser.roles.includes('superuser'),
    countriesAllowedToManage: state.currentUser.countriesAllowedToManage,
  }
}

const connectedNavigation = withRouter(connect(mapStateToProps)(Navigation))

export default connectedNavigation
