import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { forEach } from 'lodash'

import './exceptionsPreviewTable.css'
import { helpers } from 'helpers/helpers'

const ExceptionsPreviewTable = props => {
  const formatDeliveryWindow = (date, slot) => {
    const formattedDate = moment(date).format('ddd MMM DD')
    const description = `${formattedDate}, ${slot.shipperName} ${slot.from}-${slot.to}`

    return slot['priceTierIdentifier']
      ? `${description}, (${slot['priceTierIdentifier']})`
      : description
  }

  const regularCutoffDays = exception => {
    return formatCutoffDaysText(exception.cutoffDays)
  }

  const exceptionalCutoffDays = exception => {
    const exceptionalDeliveryDate = moment(exception.exceptionalDeliveryDate).format('ddd MMM DD')
    const exceptionalCutoffDate = moment(exception.exceptionalCutoffDate).format('ddd MMM DD')
    const cutoffDays = moment(exceptionalDeliveryDate).diff(exceptionalCutoffDate, 'days')

    return formatCutoffDaysText(cutoffDays)
  }

  const formatCutoffDaysText = cutoffDays => {
    return `Cutoff days: ${cutoffDays} day${cutoffDays > 1 ? 's' : ''}`
  }

  const displayExceptions = () => {
    const tableRows = []

    forEach(props.exceptions, (exception, index) => {
      const regularDeliveryInfo = formatDeliveryWindow(
        exception.regularDeliveryDate,
        exception.regularSlot,
      )

      const exceptionalDeliveryInfo = formatDeliveryWindow(
        exception.exceptionalDeliveryDate,
        exception.exceptionalSlot,
      )

      tableRows.push(
        <tr key={index} className='exceptions-preview-table__exception-row'>
          <td>
            {exception.region} ({exception.transitTime})
          </td>
          {helpers.showSuburbs(props.country) && <td>{exception.suburb}</td>}
          <td>
            <div>
              <span className='exceptions-preview-table__delivery-info'>{regularDeliveryInfo}</span>
              <br />
              <span className='exceptions-preview-table__cutoff_days cutoff-days'>
                {regularCutoffDays(exception)}
              </span>
            </div>
          </td>
          <td>
            <div>
              <span className='exceptions-preview-table__delivery-info'>
                {exceptionalDeliveryInfo}
              </span>
              <br />
              <span className='exceptions-preview-table__exceptional_cutoff_days cutoff-days'>
                {exceptionalCutoffDays(exception)}
              </span>
            </div>
          </td>
        </tr>,
      )
    })

    return tableRows
  }

  return (
    <table className='exceptions-preview-table'>
      <tbody>
        <tr>
          <th>Region</th>
          {helpers.showSuburbs(props.country) && <th>Suburb</th>}
          <th>Regular</th>
          <th>Exceptional</th>
        </tr>
        {displayExceptions()}
      </tbody>
    </table>
  )
}

ExceptionsPreviewTable.propTypes = {
  country: PropTypes.string,
  exceptions: PropTypes.array.isRequired,
}

export default ExceptionsPreviewTable
