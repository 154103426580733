import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { isEmpty, mapValues } from 'lodash'
import Skeleton from '@mui/material/Skeleton'
import Fade from '@mui/material/Fade'

import formatDate from 'helpers/formatDate'
import PaginatedBaseTable from 'components/shared/baseTable/paginatedBaseTable'
import ViewLabelLink from './viewLabelLink/viewLabelLink'
import OrderPageLink from 'components/shared/orderPageLink/orderPageLink'

import { STATUSES } from 'helpers/constants'

import './shippingLabelsTable.css'

function ShippingLabelsTable(props) {
  const fields = [
    'shipmentNumber',
    'shipmentDate',
    'deliveryDate',
    'shipper',
    'trackingNumber',
    'viewLabel',
  ]

  const [showSkeleton, setShowSkeleton] = useState(false)

  const isLoading = props.status === STATUSES.requested

  useEffect(() => {
    if (!isLoading) {
      // Delay removing skeleton slightly for smooth transition
      const timeout = setTimeout(() => setShowSkeleton(false), 300)
      return () => clearTimeout(timeout)
    } else {
      setShowSkeleton(true)
    }
  }, [isLoading])

  const withFade = (content, loadingWhen, timeout = 300) => {
    return (
      <Fade in={loadingWhen} timeout={timeout}>
        <div>{content}</div>
      </Fade>
    )
  }

  const renderSkeleton = width => {
    const skeleton = <Skeleton animation='wave' sx={{ fontSize: '18px' }} width={width} />

    return skeleton
  }

  const rowSkeletonData = () => {
    return props.data.map(_label => ({
      shipmentNumber: renderSkeleton(160),
      shipmentDate: renderSkeleton(120),
      deliveryDate: renderSkeleton(120),
      shipper: renderSkeleton(60),
      trackingNumber: renderSkeleton(100),
      viewLabel: renderSkeleton(20),
    }))
  }

  const rowData = () => {
    const data = props.data.map(label => ({
      shipmentNumber: <OrderPageLink shipmentNumber={label.shipmentNumber} />,
      shipmentDate: formatDate(label.shipmentDate),
      deliveryDate: formatDate(label.deliveryDate),
      shipper: label.shipper,
      trackingNumber: label.trackingNumber,
      viewLabel: (
        <ViewLabelLink shipmentNumber={label.shipmentNumber} featureFlags={props.featureFlags} />
      ),
    }))

    return data.map(row => mapValues(row, value => withFade(value, !isLoading, 500)))
  }

  if (isLoading && isEmpty(props.data)) {
    return <div className='shipping-labels-table__loader'>Loading...</div>
  }

  if (isEmpty(props.data)) {
    return <div className='shipping-labels-table__no-results-msg'>No shipping labels found.</div>
  }

  return (
    <PaginatedBaseTable
      tableName='shipping-labels-table'
      headers={fields}
      data={showSkeleton ? rowSkeletonData() : rowData()}
      columnRenames={{
        shipmentNumber: 'Shipment #',
        trackingNumber: 'Tracking #',
        viewLabel: '',
      }}
      currentPage={props.currentPage}
      setCurrentPage={props.setCurrentPage}
      totalCount={props.totalCount}
      onHandleNext={props.handleNextPage}
      onHandlePrevious={props.handlePrevPage}
    />
  )
}

ShippingLabelsTable.propTypes = {
  status: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      shipmentNumber: PropTypes.string.isRequired,
      shipmentDate: PropTypes.string.isRequired,
      deliveryDate: PropTypes.string.isRequired,
      shipper: PropTypes.string.isRequired,
      trackingNumber: PropTypes.string,
    }),
  ).isRequired,
  featureFlags: PropTypes.array,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  totalCount: PropTypes.number,
  handleNextPage: PropTypes.func,
  handlePrevPage: PropTypes.func,
}

export default ShippingLabelsTable
