import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { MODAL_TYPES } from './modalTypes/index'

export const ModalContainer = props => {
  if (!props.modalType) return null

  const SpecificModal = MODAL_TYPES[props.modalType]

  return <SpecificModal data={props.data} />
}

ModalContainer.propTypes = {
  modalType: PropTypes.string,
  data: PropTypes.object,
}

function mapStateToProps(state) {
  return {
    modalType: state.modal.modalType,
    data: state.modal.data,
  }
}

export default connect(mapStateToProps)(ModalContainer)
