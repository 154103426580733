import React, { useState } from 'react'
import PropTypes from 'prop-types'

import './csvUploader.css'

const CsvUploader = props => {
  const [selectedFile, setSelectedFile] = useState(null)
  const fileUploadState = selectedFile ? selectedFile.name : 'No file chosen'

  const uploadFile = e => {
    const file = e.target.files[0]
    setSelectedFile(file)

    if (file) {
      fileUploadHandler(file)
      e.target.value = ''
    } else {
      cancelUploadHandler()
    }
  }

  const fileUploadHandler = file => {
    let fileReader = new window.FileReader()

    fileReader.readAsText(file)
    fileReader.onloadend = captureData
  }

  const cancelUploadHandler = () => {
    props.onFileUpload(null)
  }

  const captureData = e => {
    props.onFileUpload(e.target.result)
  }

  return (
    <div className='csv-uploader'>
      Upload CSV:
      <label className={props.enabled ? '' : 'disabled'}>
        {props.buttonText}
        <input type='file' accept='.csv' onChange={uploadFile} disabled={!props.enabled} />
      </label>
      <p>{fileUploadState}</p>
    </div>
  )
}

CsvUploader.defaultProps = {
  buttonText: 'Choose File',
  enabled: true,
}

CsvUploader.propTypes = {
  buttonText: PropTypes.string,
  enabled: PropTypes.bool,
  onFileUpload: PropTypes.func.isRequired,
}

export default CsvUploader
