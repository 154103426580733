import superagent from 'superagent'

export const exceptionsApi = {
  createExceptions,
  deleteExceptions,
}

function createExceptions(country, brands, orderType, regularDeliveryDate, data) {
  const url = `${process.env.REACT_APP_LOGISTICS_API_URL}/exceptions`
  const logisticsToken = localStorage.getItem('logisticsToken')

  return superagent
    .post(url)
    .send({
      country,
      brands,
      order_type: orderType,
      regular_delivery_date: regularDeliveryDate,
      exceptions: data,
    })
    .set('Authorization', `Bearer ${logisticsToken}`)
    .set('Content-Type', 'application/json')
    .set('X-Issuer', 'ziplog')
}

function deleteExceptions(country, brands, orderType, regularDeliveryDate, data) {
  const url = `${process.env.REACT_APP_LOGISTICS_API_URL}/exceptions`
  const logisticsToken = localStorage.getItem('logisticsToken')

  return superagent
    .delete(url)
    .send({
      country,
      brands,
      order_type: orderType,
      regular_delivery_date: regularDeliveryDate,
      exceptions: data,
    })
    .set('Authorization', `Bearer ${logisticsToken}`)
    .set('Content-Type', 'application/json')
    .set('X-Issuer', 'ziplog')
}
