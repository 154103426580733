import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { MultiSelect } from 'react-multi-select-component'

import { clearData, setGlobalFilter } from 'actions/index'
import AppConfig from 'config'

export const MultipleBrandsFilter = props => {
  const upperCasedOptions = options => {
    return options.map(i => {
      const upperCased = i.toUpperCase()
      return { label: upperCased, value: upperCased }
    })
  }

  const brands = upperCasedOptions(props.brands)
  const [selected, setSelected] = useState([])
  const overrideStrings = {
    allItemsAreSelected: 'All Brands',
    selectSomeItems: 'Please select',
  }

  useEffect(() => {
    props.dispatch(clearData(props.context))
    props.dispatch(
      setGlobalFilter(
        'multipleBrands',
        selected.map(a => a.value),
      ),
    )
  }, [selected])

  return (
    <div className='brands-filter multi-select-filter'>
      <label className='filter__label'>Brands</label>
      <MultiSelect
        options={brands}
        value={selected}
        onChange={setSelected}
        labelledBy='Select'
        overrideStrings={overrideStrings}
      />
    </div>
  )
}

MultipleBrandsFilter.propTypes = {
  dispatch: PropTypes.func.isRequired,
  context: PropTypes.string.isRequired,
  brands: PropTypes.array.isRequired,
}

MultipleBrandsFilter.defaultProps = {
  brands: AppConfig.brands,
}

const mapStateToProps = state => ({
  brands: state.globalFilters.multipleBrands,
})

const connectedFilter = connect(mapStateToProps)(MultipleBrandsFilter)

export default connectedFilter
