import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import GraphqlPostButton from 'components/shared/graphqlPostButton/graphqlPostButton'
import { DELIVERY_DATE_EXCEPTIONS } from 'actions/context'

export class DeliveryDateExceptionsPostButton extends React.PureComponent {
  hasMissingFields() {
    const { country, brands, regularDeliveryDate } = this.props

    return !country || !brands.length > 0 || !regularDeliveryDate
  }

  gaParams() {
    return JSON.stringify(this.generateGraphqlApiData())
  }

  generateGraphqlApiData() {
    return {
      country: this.props.country,
      brands: this.props.brands,
      regularDeliveryDate: this.props.regularDeliveryDate,
      orderType: this.props.orderType,
    }
  }

  render() {
    return (
      <GraphqlPostButton
        data={this.generateGraphqlApiData()}
        disabled={this.props.isLoading || this.hasMissingFields()}
        context={DELIVERY_DATE_EXCEPTIONS}
        gaLabel={this.gaParams()}
      />
    )
  }
}

DeliveryDateExceptionsPostButton.defaultProps = {
  brands: [],
}

DeliveryDateExceptionsPostButton.propTypes = {
  country: PropTypes.string,
  brands: PropTypes.array,
  regularDeliveryDate: PropTypes.string,
  orderType: PropTypes.string,
  isLoading: PropTypes.bool,
}

function mapStateToProps(state) {
  return {
    isLoading: state.deliveryDateExceptions.isLoading,
    country: state.globalFilters.country,
    brands: state.globalFilters.multipleBrands,
    regularDeliveryDate: state.globalFilters.regularDeliveryDate,
    orderType: state.globalFilters.orderType,
  }
}

const connectedDeliveryDateExceptionsPostButton = connect(mapStateToProps)(
  DeliveryDateExceptionsPostButton,
)

export default connectedDeliveryDateExceptionsPostButton
