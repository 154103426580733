import React from 'react'
import PropTypes from 'prop-types'

import { nextWeeks } from 'helpers/dateHelper'

import './firstDeliveryWeekPicker.css'
import moment from 'moment'

class FirstDeliveryWeekPicker extends React.Component {
  onWeekSelect = e => {
    this.props.onChange(e.target.value || null)
  }

  mondayNextWeek() {
    const nextWeek = moment().add(1, 'week')
    // moment considers Sunday as the start of the week.
    // Since we need to start with Monday, we add one more day
    return nextWeek.startOf('week').add(1, 'day')
  }

  renderOptions() {
    const startDate = this.mondayNextWeek()
    const nextFourWeeks = nextWeeks(4, startDate)

    return nextFourWeeks.map((week, index) => this.renderOption(week, index))
  }

  renderNullOption() {
    return (
      <label className='first-delivery-week-picker__option' key={4}>
        <input
          type='radio'
          name='first-delivery-week'
          value={null}
          checked={this.props.selectedFirstDeliveryWeek === null}
          onChange={this.onWeekSelect}
          className='first-delivery-week-picker__input'
        />
        <span>Immediately available</span>
      </label>
    )
  }

  renderOption(week, index) {
    return (
      <label className='first-delivery-week-picker__option' key={index}>
        <input
          type='radio'
          name='first-delivery-week'
          value={week.iso8601Week}
          checked={this.props.selectedFirstDeliveryWeek === week.iso8601Week}
          onChange={this.onWeekSelect}
          className='first-delivery-week-picker__input'
        />
        <span>{`${week.startDate} - ${week.endDate} (CW${week.weekNumber})`}</span>
      </label>
    )
  }

  render() {
    return (
      <div className='first-delivery-week-picker'>
        <p className='first-delivery-week-picker__title'>
          What will be the first delivery date for existing orders?
        </p>
        <p className='first-delivery-week-picker__subtitle'>
          Changes will be reflected in the funnel immediately after saving.
        </p>

        <form className='first-delivery-week-picker__form'>
          {this.props.allowNullFirstOption && this.renderNullOption()}
          {this.renderOptions()}
        </form>
      </div>
    )
  }
}

FirstDeliveryWeekPicker.propTypes = {
  selectedFirstDeliveryWeek: PropTypes.string,
  onChange: PropTypes.func,
  allowNullFirstOption: PropTypes.bool,
}

export default FirstDeliveryWeekPicker
