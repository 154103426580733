import {
  DELIVERY_OPTIONS_CREATE_CSV_UPLOADED,
  DELIVERY_OPTIONS_CREATE_REQUESTED,
  DELIVERY_OPTIONS_UPDATE_CSV_UPLOADED,
  DELIVERY_OPTIONS_UPDATE_REQUESTED,
  DELIVERY_OPTIONS_PAUSE_CSV_UPLOADED,
  DELIVERY_OPTIONS_PAUSE_REQUESTED,
  DELIVERY_OPTIONS_PAUSE_FAILED,
  DELIVERY_OPTIONS_PAUSE_ERRORS_CLEAR,
} from './types'

export const createDeliveryOptionsCsvUpload = data => {
  return {
    type: DELIVERY_OPTIONS_CREATE_CSV_UPLOADED,
    csvContent: data,
  }
}

export const createDeliveryOptions = (country, brand, data, firstDeliveryWeek) => {
  return {
    type: DELIVERY_OPTIONS_CREATE_REQUESTED,
    country: country,
    brand: brand,
    csvContent: data,
    firstDeliveryWeek: firstDeliveryWeek,
  }
}

export const updateDeliveryOptionsCsvUpload = data => {
  return {
    type: DELIVERY_OPTIONS_UPDATE_CSV_UPLOADED,
    csvContent: data,
  }
}

export const updateDeliveryOptions = (country, brand, csvContent, firstDeliveryWeek) => {
  return {
    type: DELIVERY_OPTIONS_UPDATE_REQUESTED,
    country: country,
    brand: brand,
    csvContent: csvContent,
    firstDeliveryWeek: firstDeliveryWeek,
  }
}

export const pauseDeliveryOptionsCsvUpload = data => {
  return {
    type: DELIVERY_OPTIONS_PAUSE_CSV_UPLOADED,
    csvContent: data,
  }
}

export const pauseDeliveryOptions = (country, brand, csvContent) => {
  return {
    type: DELIVERY_OPTIONS_PAUSE_REQUESTED,
    country: country,
    brand: brand,
    csvContent: csvContent,
  }
}

export const pauseDeliveryOptionsErrorReturned = errors => ({
  type: DELIVERY_OPTIONS_PAUSE_FAILED,
  errors: errors,
})

export const pauseDeliveryOptionsErrorClear = () => ({
  type: DELIVERY_OPTIONS_PAUSE_ERRORS_CLEAR,
})
