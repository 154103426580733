import React from 'react'
import WarningIcon from '@mui/icons-material/Warning'

import './cutoffDaysWarning.css'

export const CutoffDaysWarning = () => {
  const cutoffWarningText =
    'Updating cutoff to less than 3 days might affect the payment ' +
    'recovery rate as the second billing would be disabled.'

  return (
    <div className='cutoff-warning'>
      <WarningIcon />
      <p>{cutoffWarningText}</p>
    </div>
  )
}

export default CutoffDaysWarning
