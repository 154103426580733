import {
  DELIVERY_OPTIONS_UPDATE_CSV_UPLOADED,
  DELIVERY_OPTIONS_UPDATE_REQUESTED,
  DELIVERY_OPTIONS_UPDATE_SUCCEEDED,
  DELIVERY_OPTIONS_UPDATE_FAILED,
} from 'actions/types'

const initialState = {
  isLoading: false,
  csvContent: null,
  responseBody: null,
}

const deliveryOptionsUpdate = (state = initialState, action) => {
  switch (action.type) {
    case DELIVERY_OPTIONS_UPDATE_CSV_UPLOADED:
      return {
        ...state,
        csvContent: action.csvContent,
      }
    case DELIVERY_OPTIONS_UPDATE_REQUESTED:
      return {
        ...state,
        isLoading: true,
      }
    case DELIVERY_OPTIONS_UPDATE_SUCCEEDED:
      return initialState
    case DELIVERY_OPTIONS_UPDATE_FAILED:
      return {
        isLoading: false,
        csvContent: null,
        responseBody: action.data.responseBody,
      }
    default:
      return state
  }
}

export default deliveryOptionsUpdate
